import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';

export const BiometricsActions = createActionGroup({
  source: 'biometrics',
  events: {
    'Set Biometric Security': props<{
      biometricSecurity: BiometricSecurityType;
    }>(),
    'Set Biometric Security On': emptyProps(),
    'Set Biometric Security Off': emptyProps(),
    'Save Biometric Security': emptyProps(),
    'Check Biometrics Saved': emptyProps(),
    'Authenticate Setup': emptyProps(),
    Authenticate: emptyProps(),
    Clear: emptyProps(),
    'Getting Biometrics': props<{ gettingBiometrics: boolean }>(),
    'Initialize Biometrics Store': emptyProps(),
    'Initial Authentication': emptyProps(),
    'Request Biometrics': props<{ requestBiometrics: boolean }>(),
    'Completed Biometrics On Register': emptyProps(),
    'Set Biometrics On And Continue': emptyProps(),
  },
});

export enum BiometricSecurityType {
  on = 'on',
  off = 'off',
  unavailable = 'unavailable',
}

interface BiometricsState {
  biometricSecurity: BiometricSecurityType;
  gettingBiometrics: boolean;
  requestBiometrics: boolean;
}

const initialState: BiometricsState = {
  biometricSecurity: BiometricSecurityType.off,
  gettingBiometrics: false,
  requestBiometrics: false,
};

export const biometricsFeature = createFeature({
  name: 'biometrics',
  reducer: createReducer(
    initialState,
    on(
      BiometricsActions.setBiometricSecurity,
      (state, { biometricSecurity }) => ({
        ...state,
        biometricSecurity,
      })
    ),
    on(BiometricsActions.gettingBiometrics, (state, { gettingBiometrics }) => ({
      ...state,
      gettingBiometrics,
    })),
    on(BiometricsActions.requestBiometrics, (state, { requestBiometrics }) => ({
      ...state,
      requestBiometrics,
    })),
    on(BiometricsActions.clear, () => initialState)
  ),
});

export const biometricsSecuritySelector =
  biometricsFeature.selectBiometricSecurity;
export const biometricsSecurityIsONSelector = createSelector(
  biometricsSecuritySelector,
  value => value === BiometricSecurityType.on
);

export const biometricsSecurityIsUnAvailableSelector = createSelector(
  biometricsSecuritySelector,
  value => value === BiometricSecurityType.unavailable
);
export const gettingBiometricsSelector =
  biometricsFeature.selectGettingBiometrics;
export const requestBiometricsSelector =
  biometricsFeature.selectRequestBiometrics;
